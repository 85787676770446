import {
  user,
  documentDuplicate,
  key,
  archiveBox,
  heart,
  shoppingCart,
  squaresPlus,
  clipboardDocumentList,
  archiveBoxXMark,
  bellAlert,
  devicePhoneMobile,
  envelope,
  forward,
  arrowsRightLeft,
  photo,
} from "solid-heroicons/outline";

import { Permission } from "~/services/roma-api/account/types";

// export type AccountNavItem = {
//   label: string;
//   href: string;
//   icon: IconPath;
//   permission: Permission | null;
//   new?: boolean;
// };

export type AccountNavItem = {
  label: string;
  icon: IconPath;
  permission: Permission | null;
  new?: boolean;
} & ({ href: string } | { onSelect: () => void });

export const accountNav: AccountNavItem[] = [
  {
    label: "My Profile",
    href: "/account/profile",
    icon: user,
    permission: Permission.ViewProfile,
  },
  {
    label: "Change Password",
    href: "/account/password",
    icon: key,
    permission: Permission.ResetPassword,
  },
  {
    label: "Stock Notifications",
    href: "/account/stock-notifications",
    icon: bellAlert,
    permission: null,
    new: true,
  },
  {
    label: "Contact Emails",
    href: "/account/notifications",
    icon: envelope,
    permission: null,
  },
  {
    label: "SMS Mobile Service",
    href: "/account/mobile",
    icon: devicePhoneMobile,
    permission: null,
    new: true,
  },
  {
    label: "Open Orders & History",
    href: "/account/history",
    icon: archiveBox,
    permission: Permission.ViewPurchaseHistory,
  },
  {
    label: "Documents",
    href: "/account/documents",
    icon: documentDuplicate,
    permission: Permission.AccessDocuments,
  },
  {
    label: "Favorites",
    href: "/account/favorites",
    icon: heart,
    permission: null,
  },
];

export const shoppingNav: AccountNavItem[] = [
  {
    label: "Order",
    href: "/order",
    icon: shoppingCart,
    permission: Permission.PlaceOrder,
  },
  {
    label: "Express Order",
    href: "/order/express",
    icon: forward,
    permission: Permission.PlaceOrder,
  },
  {
    label: "Art Fulfillment",
    href: "/order/art-fulfillment",
    icon: photo,
    permission: Permission.PlaceOrder,
    new: true,
  },
  {
    label: "Corner Sample Shop",
    href: "/shop?samples=true",
    icon: squaresPlus,
    permission: Permission.PlaceOrder,
  },
  {
    label: "Returns & Exchanges",
    href: "/support/returns-request",
    icon: arrowsRightLeft,
    permission: Permission.ReturnsForm,
  },
];

export const adminNav: AccountNavItem[] = [
  {
    label: "Order Approval",
    href: "/account/admin/orders",
    icon: clipboardDocumentList,
    permission: Permission.ApproveSamples,
  },
  {
    label: "BPS Order Approval",
    href: "/account/admin/orders",
    icon: clipboardDocumentList,
    permission: Permission.BPSApproveOrders,
  },
  {
    label: "Product Archive",
    href: "/shop?archive=true",
    icon: archiveBoxXMark,
    permission: Permission.ViewArchive,
  },
];

export const filterByPermissions = (
  list: AccountNavItem[],
  permissions: Permission[]
) => {
  if (!list || !permissions) return [];
  return list.filter(
    (item) =>
      permissions.includes(item.permission as Permission) ||
      item.permission === null
  );
};
